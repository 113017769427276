import React from 'react';
import "./Gifvs3.css"


const Gifvs3 = () => {



  return (

    <div className='mainsecvsmainpssa'>
   
    </div>
  );
};

export default Gifvs3;
